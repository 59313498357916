<template>
    <div class="d-flex justify-content-center align-items-center">
        <img src="../assets/img/logo.png" height="auto" width="120px">
    </div>
</template>

<script>
export default {
    name: "TopNav"
}
</script>